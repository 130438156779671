import * as React from "react";
import PropTypes from "prop-types";

const Pricing = ({ data }) => (
  <div className="products">
    {data.map((price) => (
      <section key={price.plan} className="section">
        <h4>
          {price.plan}
        </h4>
        <p>from</p>
        <h2>
          £{price.price}
        </h2>
        <p>{price.description}</p>
      </section>
    ))}
  </div>
);

Pricing.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      plan: PropTypes.string,
      price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      description: PropTypes.string,
    })
  ),
};

export default Pricing;
